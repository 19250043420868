export default {
  setUserTokens(state, tokens) {
    state.tokens = tokens;
  },
  setUserProfile(state, profile) {
    state.profile = profile;
  },
  replaceDog(state, updatedDog) {
    const updatedDogs = state.profile.dogs.map((dog) =>
      dog._id === updatedDog._id ? updatedDog : dog
    );
    state.profile.dogs = updatedDogs;
  },
  removeDogFromOwner(state, dogId) {
    const updatedDogs = state.profile.dogs.filter((dog) => dog._id !== dogId);
    state.profile.dogs = updatedDogs;
  },
};
