import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import "./styles/element-variables.scss";

import Maska from "maska";
import BaseButton from "./ui/BaseButton.vue";
import BaseSpinner from "./ui/BaseSpinner.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faRunning } from "@fortawesome/free-solid-svg-icons/faRunning";
import { faDog } from "@fortawesome/free-solid-svg-icons/faDog";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faTrophy } from "@fortawesome/free-solid-svg-icons/faTrophy";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

import VueClickAway from "vue3-click-away";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(Maska);
app.use(VueClickAway);

library.add(
  faTh,
  faHome,
  faRunning,
  faDog,
  faPlusCircle,
  faMinusCircle,
  faTrophy,
  faMedal,
  faCalendarAlt,
  faMapMarkerAlt,
  faUser,
  faUserCircle,
  faAddressCard,
  faAward,
  faEdit,
  faTrashAlt,
  faClipboard,
  faClipboardList
);

app.component("base-button", BaseButton);
app.component("base-spinner", BaseSpinner);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
