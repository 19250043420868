import { Trial } from "@/models/trialModel";
import { getTrialClasses } from "@/routes/trial";

import {
  getDogClasses,
  loadDogActiveTrial,
  updateDog,
  updateDogClassRegistration,
  getTitlePoints,
} from "@/routes/dog";

import { trialClassRegistration, trialClassRemoval } from "@/routes/trial";

export default {
  async setDog(context, dog) {
    context.commit("setDog", dog);
    context.commit("setDogActiveTitles", dog.titles);
  },
  setDogUpcomingTrials(context, upcomingTrials) {
    context.commit("setDogUpcomingTrials", upcomingTrials);
  },
  setDogPastTrials(context, pastTrials) {
    context.commit("setDogPastTrials", pastTrials);
  },
  setDogHandlers(context, handlers) {
    context.commit("setDogHandlers", handlers);
  },
  setDogActiveTrial(context, trial) {
    context.commit("setDogActiveTrial", trial);
  },
  async loadDogActiveTrial(context, trialId) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await loadDogActiveTrial(token, trialId);

    if (response.status !== 200) {
      console.error("Error DA1000 getting single trial");
      throw Error();
    }

    context.commit("setDogActiveTrial", new Trial(response.data));
  },
  async setDogActiveTrialClasses(context, trialId) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await getTrialClasses(token, trialId);

    if (response.status !== 200) {
      console.error("Error DA1300 getting trial classes");
      throw Error();
    }

    context.commit("setDogActiveTrialClasses", response.data);
  },
  setDogActiveClass(context, trialClass) {
    context.commit("setDogActiveClass", trialClass);
  },
  setDogActiveClasses(context, activeClasses) {
    context.commit("setDogActiveClasses", activeClasses);
  },
  async loadDogActiveClasses(context) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const dogId = context.rootGetters["dog/getDog"]._id;
    const trial = context.rootGetters["dog/getDogActiveTrial"];

    if (token && dogId && trial) {
      const response = await getDogClasses(token, dogId, trial._id);

      if (response.status !== 200) {
        console.log("Error DA1100 getting dog classes");
        throw Error();
      }

      let activeClasses = response.data;

      activeClasses.sort(
        (a, b) =>
          new Date(a.classDate).getTime() - new Date(b.classDate).getTime()
      );

      activeClasses.forEach((classDate) =>
        classDate.trialClasses.sort((a, b) => {
          let x = a.trialClass.toUpperCase();
          let y = b.trialClass.toUpperCase();

          return x === y ? 0 : x > y ? 1 : -1;
        })
      );

      context.commit("setDogActiveClasses", response.data);
    }
  },
  async setDogActiveTitles(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const dogId = payload.dogId;
    const titles = payload.titles;

    for (const titleIndex in titles) {
      const titlePoints = await getTitlePoints(token, dogId, {
        title: titles[titleIndex].title,
      });
      titles[titleIndex] = {
        ...titles[titleIndex],
        titlePoints: titlePoints.data,
      };
    }

    context.commit("setDogActiveTitles", titles);
  },
  // update actions
  async updateDog(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    if (token) {
      const response = await updateDog(token, payload.dogId, payload.update);

      if (response.status !== 200) {
        console.log("Error DA 1300 updating dog");
        throw Error();
      }

      context.commit("setDog", response.data);
      context.commit("setDogActiveTitles", response.data.titles);
    }
  },
  async registerForTrialClassDogView(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await trialClassRegistration(
      token,
      ids.trialId,
      ids.classId,
      ids.dogId,
      ids.handler
    );

    if (response.status !== 200) {
      console.error("Error EA1300 registering for trial class", response);
      throw new Error();
    }

    // Add registered class to dogActiveClasses
    const trialClass = response.data.trialClass;

    context.commit("addClassToDogActiveClasses", trialClass);
    context.commit("removeClassFromDogActiveTrialClasses", trialClass);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },

  async updateClassRegistration(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    if (token) {
      const response = await updateDogClassRegistration(
        token,
        payload.dogId,
        payload.classId,
        payload.update
      );

      if (response.status !== 200) {
        console.log("Error DA1200 updating dog class registration");
        throw Error();
      }

      const updatedActiveClasses = context.state.dogActiveClasses.map(
        (classDate) => {
          return {
            ...classDate,
            trialClasses: classDate.trialClasses.map((trialClass) => {
              if (trialClass._id === payload.classId) {
                return {
                  ...trialClass,
                  registrations: trialClass.registrations.map((registration) =>
                    registration.dogId === payload.dogId
                      ? { ...registration, ...payload.update }
                      : registration
                  ),
                };
              } else {
                return trialClass;
              }
            }),
          };
        }
      );
      context.commit("setDogActiveClasses", updatedActiveClasses);
    }
  },

  // Delete and remove actions
  async removeDogFromTrialClass(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await trialClassRemoval(
      token,
      ids.trialId,
      ids.trialClass._id,
      ids.dogId
    );

    if (response.status !== 200) {
      console.error("Error DA1500 removing from trial class", response);
      throw new Error();
    }

    let updatedActiveClasses = await context.rootGetters[
      "dog/getDogActiveClasses"
    ].map((classDate) => {
      if (classDate.classDate === ids.trialClass.classDate) {
        return {
          ...classDate,
          trialClasses: classDate.trialClasses.filter(
            (trialClass) => trialClass._id !== ids.trialClass._id
          ),
        };
      } else {
        return classDate;
      }
    });

    context.commit("setDogActiveClasses", updatedActiveClasses);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },
};
