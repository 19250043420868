import auth0 from "auth0-js";
import { User } from "@/models/userModel";

import {
  loginCheck,
  addDog,
  updateUserProfile,
  removeDogIdFromUser,
} from "@/routes/user";

// need .env file setup in root of directory for theset to work
const webAuth = new auth0.WebAuth(JSON.parse(process.env.VUE_APP_WEBAUTH));
const client_id = process.env.VUE_APP_CLIENTID;
const redirectUri = process.env.VUE_APP_AUTH0_REDIRECTURI;
const returnTo = process.env.VUE_APP_AUTH0_RETURNTO;

export default {
  login({ dispatch, commit }) {
    webAuth.popup.authorize(
      {
        redirectUri,
      },
      async function (err, authResults) {
        if (authResults && authResults.accessToken && authResults.idToken) {
          const tokens = {
            accessToken: authResults.accessToken,
            idToken: authResults.idToken,
            expiry: new Date().getTime() + authResults.expiresIn * 1000,
          };
          localStorage.setItem("agilitytokens", JSON.stringify(tokens));
          dispatch("setUserTokens", tokens);

          const [authProvider] = authResults.idTokenPayload.sub.split("|");
          const authProfile =
            authProvider === "google-oauth2"
              ? {
                  ...authResults.idTokenPayload,
                  firstName: authResults.idTokenPayload.given_name,
                  lastName: authResults.idTokenPayload.family_name,
                }
              : authResults.idTokenPayload;

          const response = await loginCheck(
            tokens.idToken,
            new User(authProfile)
          );

          if (response.status !== 200) {
            console.error("Error UA1000 login check", response);
            throw new Error();
          }
          commit("setUserProfile", new User(response.data));
        } else {
          console.log("error: ", err);
        }
      }
    );
  },
  logout() {
    webAuth.logout({
      returnTo,
      client_id,
    });
    localStorage.removeItem("agilitytokens");
  },
  handleAuthCallback() {
    webAuth.popup.callback();
  },
  setUserTokens(context, tokens) {
    context.commit("setUserTokens", tokens);
  },
  getAuth0UserProfile({ dispatch, commit }) {
    const tokens = JSON.parse(localStorage.getItem("agilitytokens"));
    webAuth.client.userInfo(tokens.accessToken, async function (err, user) {
      if (err) {
        console.error("error getting user info", err);
        localStorage.removeItem("agilitytokens");
      } else {
        await dispatch("setUserTokens", tokens);

        const response = await loginCheck(tokens.idToken, new User(user));

        if (response.status !== 200) {
          console.error("Error UA1000 login check", response);
          throw new Error();
        }
        commit("setUserProfile", new User(response.data));
      }
    });
  },
  async updateUserProfile(context) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const userId = context.rootGetters["user/getUserId"];

    const response = await updateUserProfile(token, userId);

    if (response.status !== 200) {
      console.error("Error UA1200 updating user profile: ", response);
    }

    context.commit("setUserProfile", new User(response.data));
  },
  async clearUserProfile(context) {
    context.commit("setUserProfile", {});
  },
  async addDog(context, dog) {
    const userId = context.rootGetters["user/getUserId"];
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await addDog(token, userId, dog);

    if (response.status !== 200) {
      console.error("Error UA1100 adding dog: ", response);
      throw new Error();
    }

    const newProfile = new User(response.data);

    context.commit("setUserProfile", newProfile);
  },
  async removeDogFromOwner(context, dogId) {
    const userId = context.rootGetters["user/getUserId"];
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await removeDogIdFromUser(token, userId, dogId);

    if (response.status !== 200) {
      console.error("Error UA 1200 removing dog from owner", response);
      throw new Error();
    }

    context.commit("removeDogFromOwner", dogId);
  },
};
