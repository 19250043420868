<template>
  <el-menu mode="horizontal">
    <el-menu-item class="logo">
      <router-link :to="{ name: 'ownertrials' }"
        ><img :src="agilityChimpLogo" alt="Agility Logo" />Agility
        Chimp</router-link
      >
    </el-menu-item>
    <el-menu-item
      ><router-link :to="{ name: 'howitworks' }"
        >How It Works</router-link
      ></el-menu-item
    >
    <el-menu-item
      ><router-link :to="{ name: 'pricing' }"
        >Pricing</router-link
      ></el-menu-item
    >
    <el-menu-item
      ><router-link :to="{ name: 'about' }">About Us</router-link></el-menu-item
    >
    <el-menu-item
      ><router-link :to="{ name: 'privacy' }"
        >Privacy Policy</router-link
      ></el-menu-item
    >
    <el-menu-item
      ><router-link :to="{ name: 'support' }"
        >Support</router-link
      ></el-menu-item
    >
    <div class="menubar-right-side" v-if="userHasProfile">
      <font-awesome-icon
        :onClick="handleDashboard"
        :icon="['fa', 'th']"
      ></font-awesome-icon>

      <el-popover
        ref="userActions"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="user-actions">
          <span v-if="!userHasProfile" @click="handleLogin">login</span>
          <span v-if="userHasProfile" @click="handleLogout">logout</span>
        </div>
      </el-popover>
      <span v-popover:userActions
        ><font-awesome-icon :icon="['far', 'user-circle']"></font-awesome-icon
      ></span>
    </div>
    <div v-else class="menubar-right-side">
      <base-button v-if="!userHasProfile" class="primary" @click="handleLogin"
        >Login</base-button
      >
    </div>
  </el-menu>
</template>

<script>
import agilityChimpLogo from "../assets/Agility-Chimp-Logo-200x200.png";

export default {
  data() {
    return {
      agilityChimpLogo,
    };
  },
  computed: {
    userAuthorized() {
      return this.$store.getters["user/isAuthorized"];
    },
    userHasProfile() {
      const userProfile = this.$store.getters["user/getUserProfile"];
      //check to see if userProfile is an empty object
      return Object.keys(userProfile).length !== 0;
    },
  },
  watch: {
    userAuthorized(isAuthorized, prevIsAuthorized) {
      isAuthorized &&
        prevIsAuthorized === false &&
        this.$router.replace({ name: "ownertrials" });
    },
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("user/login");
    },
    handleLogout() {
      this.$store.dispatch("user/logout");
    },
    handlDogState() {
      console.log(this.$store.getters["dog/getDogState"]);
    },
    handleDashboard() {
      this.$router.push({ name: "ownertrials" });
    },
    logProfile() {
      console.log(this.$store.getters["user/getUserProfile"]);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  position: relative;
}

.el-menu .el-menu-item,
.el-menu .el-menu-item.is-active {
  border-bottom: none;
  color: $textSecondary;

  &:hover,
  &:focus {
    color: $secondary;
  }
}

.el-menu-item.logo {
  margin-right: 75px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1rem;
  color: $textSecondary;

  img {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
}

.menubar-right-side {
  position: absolute;
  top: 12px;
  right: 20px;

  svg {
    font-size: 25px;
    color: $tertiary-75;
    vertical-align: middle;
    margin: 5px 10px 0;
  }

  svg:hover,
  .useRollover:hover {
    color: $secondary;
    cursor: pointer;
  }

  button {
    margin-right: 10px;
  }
}

.user-actions {
  text-align: center;
  font-size: 1.05rem;

  span {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  span:hover {
    color: $secondary;
    cursor: pointer;
  }
}
</style>
