import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state() {
    return {
      dog: null,
      lastFetchDogUpcomingTrials: null,
      dogUpcomingTrials: [],
      lastFetchDogPastTrials: null,
      dogPastTrials: [],
      dogActiveTrial: null,
      dogActiveTrialClasses: [],
      dogActiveClasses: null,
      dogActiveClass: null,
      dogActiveTitles: [],
    };
  },
  getters,
  actions,
  mutations,
};
