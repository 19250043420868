export class TrialClass {
  constructor(opts = undefined) {
    if (opts !== null) {
      this._id = opts._id && opts._id;
      this.level = opts.level && opts.level;
      this.trialClass = opts.trialClass && opts.trialClass;
      this.judge = opts.judge && opts.judge;
      this.classDate = opts.classDate && opts.classDate;
      this.trialId = opts.trialId && opts.trialId;
      this.isCancelled = opts.isCancelled && opts.isCancelled;
      this.isCancelledDate = opts.isCancelledDate && opts.isCancelledDate;
      this.isDeleted = opts.isDeleted && opts.isDeleted;
      this.isDeletedDate = opts.isDeletedDate && opts.isDeletedDate;
      this.createdAt = opts.createdAt && opts.createdAt;
      this.updatedAt = opts.updatedAt && opts.updatedAt;
      this.updatedBy = opts.updatedBy && opts.updatedBy;
      this.registrations = opts.registrations ? opts.registrations : [];
      this.runOrders = opts.runOrders ? opts.runOrders : [];
    }
  }
}
