import axios from "axios";
const envURL = process.env.VUE_APP_ENVURL;

// Auth routes

export const loginCheck = async (token, authInfo) => {
  const response = await axios.post(`${envURL}/api/user/logincheck`, authInfo, {
    headers: { "x-auth-token": token },
  });
  return response;
};

// post routes
export const addDog = async (token, userId, dog) => {
  const response = await axios.post(`${envURL}/api/dog/createdog`, dog, {
    headers: { "x-auth-token": token, "x-auth-userId": userId },
  });
  return response;
};

// get routes
// this should actually be "refreshUserProfile" because
// it doesn't update the DB but refreshes the front-end user profile
export const updateUserProfile = async (token, userId) => {
  const response = await axios.get(`${envURL}/api/me`, {
    headers: {
      "x-auth-token": token,
      "x-auth-userId": userId,
    },
  });

  return response;
};

// update routes
export const updateUser = async (token, userId, update) => {
  const response = await axios.patch(
    `${envURL}/api/user/${userId}/update`,
    update,
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

export const removeDogIdFromUser = async (token, userId, dogId) => {
  const response = await axios.patch(
    `${envURL}/api/user/${userId}/removedogid/${dogId}`,
    {},
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};
