export default {
  setDog(state, dog) {
    state.dog = dog;
  },
  setDogUpcomingTrials(state, upcomingTrials) {
    state.dogUpcomingTrials = upcomingTrials;
  },
  setDogPastTrials(state, pastTrials) {
    state.dogPastTrials = pastTrials;
  },
  setDogHandlers(state, handlers) {
    state.dogHandlers = handlers;
  },
  setDogActiveTrial(state, trial) {
    state.dogActiveTrial = trial;
  },
  setDogActiveTrialClasses(state, activeTrialClasses) {
    state.dogActiveTrialClasses = activeTrialClasses;
  },
  setDogActiveClasses(state, activeClasses) {
    state.dogActiveClasses = activeClasses;
  },
  setDogActiveClass(state, trialClass) {
    state.dogActiveClass = trialClass;
  },
  setDogActiveTitles(state, titles) {
    state.dogActiveTitles = titles;
  },
  addClassToDogActiveClasses(state, trialClass) {
    state.dogActiveClasses.map((classDate) =>
      classDate.classDate === trialClass.classDate
        ? classDate.trialClasses.push(trialClass)
        : classDate
    );
  },
  removeClassFromDogActiveTrialClasses(state, trialClass) {
    const updatedActiveTrialClasses = state.dogActiveTrialClasses.map(
      (classDate) => {
        if (classDate.classDate === trialClass.classDate) {
          return {
            ...classDate,
            trialClasses: classDate.trialClasses.filter(
              (classItem) => classItem._id !== trialClass._id
            ),
          };
        } else {
          return classDate;
        }
      }
    );
    state.dogActiveTrialClasses = updatedActiveTrialClasses;
  },
  addNewActiveTitle(state, newTitle) {
    console.log("mutation: ", newTitle);
    state.dogActiveTitles.push(newTitle);
  },
};
