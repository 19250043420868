import moment from "moment";

export class Trial {
  constructor(opts = undefined) {
    if (opts !== null) {
      this._id = opts._id && opts._id;
      this.trialName = opts.trialName && opts.trialName;
      this.eventType = opts.eventType && opts.eventType;
      this.sanctionBody = opts.sanctionBody && opts.sanctionBody;
      this.startDate = opts.startDate && opts.startDate;
      this.endDate = opts.endDate && opts.endDate;
      this.runningSurface = opts.runningSurface && opts.runningSurface;
      this.outsideIndoors = opts.outsideIndoors && opts.outsideIndoors;
      this.location = opts.location && opts.location;
      this.street1 = opts.street1 && opts.street1;
      this.street2 = opts.street2 && opts.street2;
      this.city = opts.city && opts.city;
      this.state = opts.state && opts.state;
      this.zip = opts.zip && opts.zip;
      this.updatedBy = opts.updatedBy && opts.updatedBy;
      this.trialStatus = opts.trialStatus && opts.trialStatus;
      this.trialRuns = opts.trialRuns ? opts.trialRuns : [];
      this.trialClassesByDate = opts.trialClassesByDate
        ? opts.trialClassesByDate
        : [];
      this.trialRegistrations = opts.trialRegistrations
        ? opts.trialRegistrations
        : [];
    }
  }
  formatFullDate(dateToFormat) {
    return moment(dateToFormat).format("MM/DD/YYYY");
  }
  formatMonthAndDay(dateToFormat) {
    return moment(dateToFormat).format("MMM DD");
  }
}
