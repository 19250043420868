import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state() {
    return {
      lastFetchOwnerUpcomingTrials: null,
      ownerUpcomingTrials: [],
      lastFetchOwnerPastTrials: null,
      ownerPastTrials: [],
      trialToEdit: null,
    };
  },
  getters,
  actions,
  mutations,
};
