<template>
  <button v-if="!link" :class="color">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="color">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  display: inline-block;
  padding: 10px 21px;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  border: 1px solid $borderLight;
  border-radius: 4px;
}

button:hover,
a:hover {
  color: $primary;
  background-color: $primary-15;
  border: 1px solid $primary-25;
}

.primary {
  color: $textWhite !important;
  background-color: $primary;
  border: none;
}

.primary:hover {
  color: $textWhite !important;
  background-color: $primary-75;
  border: none;
}

.secondary {
  color: $textWhite !important;
  background-color: $secondary;
  border: none;
}

.secondary:hover {
  color: $textWhite !important;
  background-color: $secondary-75;
  border: none;
}

.tertiary {
  color: $textWhite !important;
  background-color: $tertiary;
  border: none;
}

.tertiary:hover {
  color: $textWhite !important;
  background-color: $tertiary-75;
  border: none;
}
</style>
