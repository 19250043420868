<template>
  <div class="popup-wrapper">
    <h1>Welcome to Agility Chimp</h1>
    <img :src="agilityChimpLogo" alt="Agility Logo" />
    <base-spinner></base-spinner>
  </div>
</template>

<script>
import agilityChimpLogo from "../../assets/Agility-Chimp-Logo-200x200.png";

export default {
  data() {
    return {
      isLoading: true,
      agilityChimpLogo,
    };
  },
  created() {
    this.$store.dispatch("user/handleAuthCallback");
  },
};
</script>

<style lang="scss" scoped>
.popup-wrapper {
  text-align: center;
  background: $siteBackground;

  img {
    margin-bottom: 20px;
  }
}
</style>
