import { Trial } from "@/models/trialModel";

export default {
  addOwnerUpcomingTrial(state, newTrial) {
    const trialExists = state.ownerUpcomingTrials.some(
      (trial) => trial._id === newTrial._id
    );

    !trialExists
      ? state.ownerUpcomingTrials.push(newTrial)
      : this.commit("trial/replaceOwnerUpcomingTrial", newTrial);
  },
  addOwnerPastTrial(state, newTrial) {
    const trialExists = state.ownerPastTrials.some(
      (trial) => trial._id === newTrial._id
    );

    !trialExists
      ? state.ownerPastTrials.push(newTrial)
      : this.commit("trial/replaceOwnerPastTrial", newTrial);
  },
  setFetchTimeOwnerUpcomingTrials(state) {
    state.lastFetchOwnerUpcomingTrials = new Date().getTime();
  },
  updateOwnerUpcomingTrials(state, trials) {
    state.ownerUpcomingTrials = trials;
  },
  setFetchTimeOwnerPastTrials(state) {
    state.lastFetchOwnerPastTrials = new Date().getTime();
  },
  updateOwnerPastTrials(state, trials) {
    state.ownerPastTrials = trials;
  },
  replaceOwnerUpcomingTrial(state, replacementTrial) {
    const updatedTrials = state.ownerUpcomingTrials.map((trial) =>
      trial._id === replacementTrial._id ? replacementTrial : trial
    );
    state.ownerUpcomingTrials = updatedTrials;
  },
  replaceOwnerPastTrial(state, replacementTrial) {
    const updatedTrials = state.ownerPastTrials.map((trial) =>
      trial._id === replacementTrial._id ? replacementTrial : trial
    );
    state.ownerPastTrials = updatedTrials;
  },
  replaceOwnerUpcomingTrialClasses(state, payload) {
    state.ownerUpcomingTrials.map((trial) => {
      trial._id === payload.trialId
        ? (trial.trialClassesByDate = payload.newTrialClasses)
        : trial;
    });
  },
  replaceOwnerPastTrialClasses(state, payload) {
    state.ownerPastTrials.map((trial) => {
      trial._id === payload.trialId
        ? (trial.trialClassesByDate = payload.newTrialClasses)
        : trial;
    });
  },
  addTrialClass(state, payload) {
    const trialClass = payload.trialClass;
    let upcomingOrPast;

    if (payload.upcomingOrPast === "upcoming") {
      upcomingOrPast = "ownerUpcomingTrials";
    } else if (payload.upcomingOrPast === "past") {
      upcomingOrPast = "ownerPastTrials";
    } else {
      upcomingOrPast = "ownerUpcomingTrials";
    }

    // find correct trail
    // in trial add trialClass._id to trialRuns
    // state.ownerUpcomingTrials.map((trial) => {

    state[upcomingOrPast].map((trial) => {
      if (trial._id === trialClass.trialId) {
        //check to see if trialClass._id already exists and if not push to trialRuns array
        trial.trialRuns.indexOf(trialClass._id === -1) &&
          trial.trialRuns.push(trialClass._id);
        //find correct date for class in trialClassesByDate
        //add class to trialClasses for that date
        trial.trialClassesByDate.map((trialClassCheck) => {
          return (
            trialClassCheck.classDate === trialClass.classDate &&
            trialClassCheck.trialClasses.push(trialClass)
          );
        });
      }
    });
  },
  replaceTrialClass(state, trialClass) {
    let existingTrial;
    let upcomingOrPast;

    // find correct trial in either upcoming or past trials
    if (
      state.ownerUpcomingTrials.find(
        (trial) => trial._id === trialClass.trialId
      )
    ) {
      existingTrial = state.ownerUpcomingTrials.find(
        (trial) => trial._id === trialClass.trialId
      );
      upcomingOrPast = "upcoming";
    } else if (
      state.ownerPastTrials.find((trial) => trial._id === trialClass.trialId)
    ) {
      existingTrial = state.ownerPastTrials.find(
        (trial) => trial._id === trialClass.trialId
      );
      upcomingOrPast = "past";
    } else {
      console.log("Trial not found!");
    }

    // find and replace the trialClass for the correct date
    const newTrial = {
      ...existingTrial,
      trialClassesByDate: existingTrial.trialClassesByDate.map(
        (trialClassByDate) => {
          if (trialClassByDate.classDate === trialClass.classDate) {
            return {
              ...trialClassByDate,
              trialClasses: trialClassByDate.trialClasses.map(
                (trialClassByDate) =>
                  trialClassByDate._id === trialClass._id
                    ? trialClass
                    : trialClassByDate
              ),
            };
          } else {
            return trialClassByDate;
          }
        }
      ),
    };

    upcomingOrPast === "upcoming" &&
      this.commit("trial/replaceOwnerUpcomingTrial", new Trial(newTrial));
    upcomingOrPast === "past" &&
      this.commit("trial/replaceOwnerPastTrial", new Trial(newTrial));
  },
  removeUpcomingTrialFromStore(state, trialId) {
    state.ownerUpcomingTrials = state.ownerUpcomingTrials.filter(
      (trial) => trial._id !== trialId
    );
  },
  removePastTrialFromStore(state, trialId) {
    state.ownerPastTrials = state.ownerPastTrials.filter(
      (trial) => trial._id !== trialId
    );
  },
  removeTrialClassFromTrial(state, payload) {
    const trialClass = payload.trialClass;
    let upcomingOrPast;

    if (payload.upcomingOrPast === "upcoming") {
      upcomingOrPast = "ownerUpcomingTrials";
    } else if (payload.upcomingOrPast === "past") {
      upcomingOrPast = "ownerPastTrials";
    }

    // console.log("upcomingOrPast: ", upcomingOrPast);
    // console.log("trialClass: ", trialClass);
    console.log("stateBefore: ", state);

    const updatedTrial = state[upcomingOrPast].map((trialCheck) => {
      if (trialCheck._id === trialClass.trialId) {
        return { ...trialCheck, trialName: "Test" };
      }
    });

    console.log("updatedTrial: ", updatedTrial);

    // state[upcomingOrPast].map((trialCheck) => {
    //   if (trialCheck._id === trialClass.trialId) {
    //     trialCheck.trialClassesByDate.map((trialClassesByDateCheck) => {
    //       trialClassesByDateCheck.classDate === trialClass.classDate &&
    //         trialClassesByDateCheck.trialClasses.filter(
    //           (trialClassCheck) => trialClassCheck._id !== trialClass._id
    //         );
    //     });
    //   }
    // });
  },
  setTrialToEdit(state, trial) {
    state.trialToEdit = trial;
  },
};
