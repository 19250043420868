import { Trial } from "@/models/trialModel";
import { TrialClass } from "@/models/trialClassModel";
import {
  createTrial,
  upcomingOwnerTrials,
  pastOwnerTrials,
  trialRegistration,
  createTrialClass,
  getSingleTrial,
  getTrialClasses,
  trialClassRegistration,
  trialClassRemoval,
  removeDogFromTrial,
  updateTrial,
  updateTrialClass,
} from "@/routes/trial";

export default {
  async createTrial(context, trial) {
    const userId = context.rootGetters["user/getUserId"];
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await createTrial(token, userId, trial);

    if (response.status !== 200) {
      console.error("Error TA1100 adding trial: ", response);
      throw new Error();
    }

    const newTrial = new Trial(response.data);

    new Date(trial.startDate) >= new Date()
      ? context.commit("addOwnerUpcomingTrial", newTrial)
      : context.commit("addOwnerPastTrial", newTrial);
  },

  async loadTrials(context, whichList) {
    const userId = context.rootGetters["user/getUserId"];
    const token = context.rootGetters["user/getUserTokens"].idToken;
    let response;

    if (whichList === "upcomingTrials") {
      // check to see if it has been 5 minutes since last fetch of data
      if (!context.getters.shouldUpdateOwnerUpcomingTrials) {
        return;
      }

      let upcomingTrials = [];
      response = await upcomingOwnerTrials(token, userId);
      response.data.forEach((trial) => upcomingTrials.push(new Trial(trial)));
      context.commit("updateOwnerUpcomingTrials", upcomingTrials);
      context.commit("setFetchTimeOwnerUpcomingTrials");
    } else if (whichList === "pastTrials") {
      // check to see if it has been 5 minutes since last fetch of data
      if (!context.getters.shouldUpdateOwnerPastTrials) {
        return;
      }

      let pastTrials = [];
      response = await pastOwnerTrials(token, userId);
      response.data.forEach((trial) => pastTrials.push(new Trial(trial)));
      context.commit("updateOwnerPastTrials", pastTrials);
      context.commit("setFetchTimeOwnerPastTrials");
    } else {
      let upcomingTrials = [];
      response = await upcomingOwnerTrials(token, userId);
      response.data.forEach((trial) => upcomingTrials.push(new Trial(trial)));
      context.commit("updateOwnerUpcomingTrials", upcomingTrials);
      context.commit("setFetchTimeOwnerUpcomingTrials");
    }

    if (response.status !== 200) {
      console.error("Error TA1200 getting trials", response);
      throw new Error();
    }
  },
  async loadSingleTrial(context, trialId) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await getSingleTrial(token, trialId);

    if (response.status !== 200) {
      console.error("Error TA1300 getting single trial");
      throw Error();
    }
    const newTrial = new Trial(response.data);

    new Date(newTrial.startDate) >= new Date()
      ? context.commit("addOwnerUpcomingTrial", newTrial)
      : context.commit("addOwnerPastTrial", newTrial);
  },
  async loadTrialClasses(context, trial) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const trialId = trial._id;

    const response = await getTrialClasses(token, trialId);

    if (response.status !== 200) {
      console.error("Error TA1400 gettting trial with classes");
      throw new Error();
    }

    const newTrialClasses = response.data;

    new Date(trial.startDate) >= new Date()
      ? context.commit("replaceOwnerUpcomingTrialClasses", {
          trialId,
          newTrialClasses,
        })
      : context.commit("replaceOwnerPastTrialClasses", {
          trialId,
          newTrialClasses,
        });
  },
  async loadTrialToEdit(context, trialId) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await getSingleTrial(token, trialId);

    if (response.status !== 200) {
      console.error("Error TA1300 getting single trial");
      throw Error();
    }
    const newTrial = new Trial(response.data);

    context.commit("setTrialToEdit", newTrial);
    return;
  },

  //update actions
  async registerForTrial(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await trialRegistration(token, ids.trialId, ids.dogId);

    if (response.status !== 200) {
      console.error("Error TA1100 registering for trial: ", response);
      throw new Error();
    }

    const newTrial = new Trial(response.data.trial);

    ids.upcomingOrPast === "upcoming" &&
      context.commit("replaceOwnerUpcomingTrial", newTrial);
    ids.upcomingOrPast === "past" &&
      context.commit("replaceOwnerPastTrial", newTrial);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },
  async addTrialClass(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await createTrialClass(token, payload.trialClass);

    if (response.status !== 200) {
      console.error("Error TA1200 adding trialClass: ", response);
      throw new Error();
    }

    context.commit("addTrialClass", {
      trialClass: response.data,
      upcomingOrPast: payload.upcomingOrPast,
    });
  },
  async registerForTrialClass(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await trialClassRegistration(
      token,
      ids.trialId,
      ids.classId,
      ids.dogId,
      ids.handler
    );

    if (response.status !== 200) {
      console.error("Error EA1300 registering for trial class", response);
      throw new Error();
    }

    const newTrialClass = new TrialClass(response.data.trialClass);

    context.commit("replaceTrialClass", newTrialClass);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },
  async updateTrial(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const trialId = payload.trialId;
    const update = payload.update;

    const response = await updateTrial(token, trialId, update);

    if (response.status !== 200) {
      console.error("Error TA1600 updating trial", response);
      throw new Error();
    }

    const updatedTrial = new Trial(response.data.value);

    if (new Date(updatedTrial.startDate) > new Date()) {
      context.commit("replaceOwnerUpcomingTrial", updatedTrial);
    } else if (new Date(updatedTrial.startDate) < new Date()) {
      context.commit("replaceOwnerPastTrial", updatedTrial);
    } else {
      console.log("updatedTrial start date was not found", updatedTrial);
    }
  },

  // Delete actions
  async removeTrialFromStore(context, trial) {
    const currentDate = new Date();
    const trialStartDate = new Date(trial.startDate);

    if (trialStartDate >= currentDate) {
      context.commit("removeUpcomingTrialFromStore", trial._id);
    } else if (trialStartDate < currentDate) {
      context.commit("removePastTrialFromStore", trial._id);
    }
  },

  async removeTrialClassFromTrial(context, payload) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const trialClass = payload.trialClass;
    const classId = payload.trialClass._id;
    const upcomingOrPast = payload.upcomingOrPast;
    const update = payload.update;
    let updatedActiveClasses;
    const response = await updateTrialClass(token, classId, update);

    if (response.status !== 200) {
      console.error("Error TA1500 removing trialClass from trial", response);
      throw new Error();
    }

    const updatedTrialClass = new TrialClass(response.data.value);

    let existingTrials =
      (await upcomingOrPast) === "upcoming"
        ? [...context.rootGetters["trial/ownerUpcomingTrials"]]
        : [...context.rootGetters["trial/ownerPastTrials"]];

    let updatedTrials = existingTrials.map((trialCheck) => {
      if (trialCheck._id === updatedTrialClass.trialId) {
        return new Trial({
          ...trialCheck,
          trialClassesByDate: trialCheck.trialClassesByDate.map(
            (trialClassesByDateCheck) =>
              trialClassesByDateCheck.classDate === updatedTrialClass.classDate
                ? {
                    ...trialClassesByDateCheck,
                    trialClasses: trialClassesByDateCheck.trialClasses.filter(
                      (trialClassCheck) =>
                        trialClassCheck._id !== updatedTrialClass._id
                    ),
                  }
                : trialClassesByDateCheck
          ),
          trialRuns: trialCheck.trialRuns.filter(
            (trialRunCheck) => trialRunCheck !== updatedTrialClass._id
          ),
        });
      } else {
        return new Trial(trialCheck);
      }
    });

    let currentActiveClasses = await context.rootGetters[
      "dog/getDogActiveTrialClasses"
    ];

    if (currentActiveClasses) {
      updatedActiveClasses = currentActiveClasses.map((classDateCheck) => {
        if (classDateCheck.classDate === trialClass.classDate) {
          return {
            ...classDateCheck,
            trialClasses: classDateCheck.trialClasses.filter(
              (trialClassCheck) => trialClassCheck._id !== trialClass._id
            ),
          };
        } else {
          return classDateCheck;
        }
      });
      context.commit("dog/setDogActiveTrialClasses", updatedActiveClasses, {
        root: true,
      });
    }

    upcomingOrPast === "upcoming" &&
      context.commit("updateOwnerUpcomingTrials", updatedTrials);

    upcomingOrPast === "past" &&
      context.commit("updateOwnerPastTrials", updatedTrials);
  },

  async removeDogFromTrial(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;

    const response = await removeDogFromTrial(token, ids.dogId, ids.trialId);

    if (response.status !== 200) {
      console.error("Error TA1400 removing dog from trial", response);
      throw new Error();
    }

    const newTrial = new Trial(response.data.trial);

    ids.upcomingOrPast === "upcoming" &&
      context.commit("replaceOwnerUpcomingTrial", newTrial);
    ids.upcomingOrPast === "past" &&
      context.commit("replaceOwnerPastTrial", newTrial);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },

  async removeFromTrialClass(context, ids) {
    const token = context.rootGetters["user/getUserTokens"].idToken;
    const response = await trialClassRemoval(
      token,
      ids.trialId,
      ids.classId,
      ids.dogId
    );

    if (response.status !== 200) {
      console.error("Error EA1400 removing from trial class", response);
      throw new Error();
    }

    const newTrialClass = new TrialClass(response.data.trialClass);

    context.commit("replaceTrialClass", newTrialClass);
    context.commit("user/replaceDog", response.data.dog, { root: true });
  },
};
