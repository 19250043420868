import axios from "axios";
const envURL = process.env.VUE_APP_ENVURL;

// post routes
export const createResults = async (token, userId, results) => {
  const response = await axios.post(
    `${envURL}/api/dog/createresults`,
    results,
    {
      headers: { "x-auth-token": token, "x-auth-userId": userId },
    }
  );
  return response;
};

export const getTitlePoints = async (token, dogId, title) => {
  const response = await axios.post(
    `${envURL}/api/dog/${dogId}/titlepoints`,
    title,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

// get routes
export const getDog = async (token, userId, dogId) => {
  const response = await axios.get(`${envURL}/api/dog/dogdetail/${dogId}`, {
    headers: { "x-auth-token": token, "x-auth-userId": userId },
  });
  return response;
};

export const getDogUpcomingTrials = async (token, dogId) => {
  const response = await axios.get(
    `${envURL}/api/dog/${dogId}/upcomingtrials`,
    { headers: { "x-auth-token": token } }
  );
  return response;
};

export const getDogPastTrials = async (token, dogId) => {
  const response = await axios.get(`${envURL}/api/dog/${dogId}/pasttrials`, {
    headers: { "x-auth-token": token },
  });
  return response;
};

export const getDogClasses = async (token, dogId, trialId) => {
  const response = await axios.get(
    `${envURL}/api/dog/${dogId}/trial/${trialId}/classes`,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

export const getDogClassResults = async (token, classId) => {
  const response = await axios.get(
    `${envURL}/api/dog/classresults/${classId}`,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

export const loadDogActiveTrial = async (token, trialId) => {
  const response = await axios.get(`${envURL}/api/trial/${trialId}`, {
    headers: { "x-auth-token": token },
  });
  return response;
};

// update routes
export const updateDog = async (token, dogId, update) => {
  const response = await axios.patch(
    `${envURL}/api/dog/${dogId}/update`,
    update,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

export const updateDogClassRegistration = async (
  token,
  dogId,
  classId,
  update
) => {
  const response = await axios.patch(
    `${envURL}/api/dog/${dogId}/class/${classId}/update`,
    update,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

export const updateResults = async (token, resultsId, update) => {
  const response = await axios.patch(
    `${envURL}/api/dog/results/${resultsId}/update`,
    update,
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

// Delete Routes
export const removeResults = async (token, dogId, classId) => {
  const response = await axios.patch(
    `${envURL}/api/dog/${dogId}/class/${classId}/remove`,
    {},
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};
