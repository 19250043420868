export default {
  getUserTokens(state) {
    return state.tokens;
  },
  getUserProfile(state) {
    return state.profile;
  },
  getUserId(state) {
    return state.profile._id;
  },
  isAuthorized(state, getters) {
    if (state.tokens.accessToken) {
      return (
        state.tokens.accessToken && new Date().getTime() < state.tokens.expiry
      );
    } else if (getters.hasLocalTokens) {
      const localTokens = JSON.parse(getters.hasLocalTokens);
      return (
        localTokens.accessToken && new Date().getTime() < localTokens.expiry
      );
    } else {
      return false;
    }
  },
  hasLocalTokens() {
    return localStorage.getItem("agilitytokens");
  },
};
