export default {
  shouldUpdateOwnerUpcomingTrials(state) {
    const lastFetch = state.lastFetchOwnerUpcomingTrials;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    // is lastFetch more than 5 minutes ago?
    return (currentTimeStamp - lastFetch) / 1000 > 300;
  },
  shouldUpdateOwnerPastTrials(state) {
    const lastFetch = state.lastFetchOwnerPastTrials;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    // is lastFetch more than 5 minutes ago?
    return (currentTimeStamp - lastFetch) / 1000 > 300;
  },
  ownerUpcomingTrials(state) {
    return state.ownerUpcomingTrials;
  },
  ownerPastTrials(state) {
    return state.ownerPastTrials;
  },
  getTrialToEdit(state) {
    return state.trialToEdit;
  },
};
