export class User {
  constructor(opts = undefined) {
    this._id = opts._id && opts._id;
    this.firstName = opts.firstName && opts.firstName;
    this.lastName = opts.lastName && opts.lastName;
    this.email = opts.email && opts.email;
    this.avatarImage = opts.avatarImage && opts.avatarImage;
    this.status = opts.status && opts.status;
    this.sub = opts.sub && opts.sub;
    this.isOrg = opts.isOrg && opts.isOrg;
    this.isSponsor = opts.isSponsor && opts.isSponsor;
    this.stripeId = opts.stripeId && opts.stripeId;
    this.dogIds = opts.dogIds ? opts.dogIds : [];
    this.dogs = opts.dogs ? opts.dogs : [];
    this.agree = opts.agree && opts.agree;
    this.emailVerified = opts.emailVerified && opts.emailVerified;
    this.organizations = opts.organizations ? opts.organizations : [];
  }
}
