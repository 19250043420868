export default {
  getDogState(state) {
    return state;
  },
  getDog(state) {
    return state.dog;
  },
  getDogUpcomingTrials(state) {
    return state.dogUpcomingTrials;
  },
  getDogPastTrials(state) {
    return state.dogPastTrials;
  },
  getDogHandlers(state) {
    return state.dogHandlers;
  },
  getDogActiveTrial(state) {
    return state.dogActiveTrial;
  },
  getDogActiveTrialClasses(state) {
    return state.dogActiveTrialClasses;
  },
  getDogActiveClasses(state) {
    return state.dogActiveClasses;
  },
  getDogActiveClass(state) {
    return state.dogActiveClass;
  },
  getDogActiveTitles(state) {
    return state.dogActiveTitles;
  },
};
