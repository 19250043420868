import { createStore } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

import userModule from "./modules/user/index.js";
import trialModule from "./modules/trial/index.js";
import dogModule from "./modules/dog/index";

export default createStore({
  state: {},
  modules: {
    user: userModule,
    trial: trialModule,
    dog: dogModule,
  },
  getters,
  actions,
  mutations,
});
