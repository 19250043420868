import { createRouter, createWebHistory } from "vue-router";
import AuthCallback from "@/components/auth/AuthCallback.vue";
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HowItWorks.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/Pricing.vue"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/Support.vue"),
  },
  {
    path: "/howitworks",
    name: "howitworks",
    component: () => import("../views/HowItWorks.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyNotice.vue"),
  },
  { name: "authcallback", path: "/authcallback", component: AuthCallback },
  {
    name: "authrequired",
    path: "/authrequired",
    component: () => import("../components/auth/AutRequired.vue"),
    meta: { unAuthorized: true },
  },
  {
    path: "/:NotFound(.*)",
    name: "notfound",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    name: "ownerDashboard",
    path: "/owner",
    component: () => import("../views/OwnerDashboardView.vue"),
    meta: { requireAuth: true },
    children: [
      {
        name: "ownerdashboard",
        path: "dashboard",
        component: () => import("../views/OwnerDashboard.vue"),
      },
      {
        name: "ownertrials",
        path: "mytrials",
        component: () => import("../views/OwnerTrials.vue"),
      },
      {
        name: "ownerdogs",
        path: "mydogs",
        component: () => import("../views/OwnerDogs.vue"),
      },
      {
        name: "adddog",
        path: "adddog",
        component: () => import("../components/dog/AddDog.vue"),
        props: true,
      },
      {
        name: "addtrial",
        path: "addtrial",
        component: () => import("../components/trial/AddTrial.vue"),
        props: true,
      },
      {
        name: "classregistration",
        path: "trial/class/registration/:trialId",
        component: () => import("../components/trial/RegisterAddClasses.vue"),
        props: true,
      },
    ],
  },
  {
    name: "dogDashboard",
    path: "/dog/:dogId",
    component: () => import("../views/DogDashboard.vue"),
    props: true,
    meta: { requireAuth: true },
    children: [
      {
        name: "dogTrialListView",
        path: "triallist/:trialType",
        component: () => import("../views/DogTrialListView.vue"),
        props: (route) => ({
          trialType: route.params.trialType,
        }),
      },
      {
        name: "dogRegistrations",
        path: "registrations",
        component: () => import("../components/dog/DogRegistration.vue"),
      },
      {
        name: "dogRegistrations",
        path: "registrations",
        component: () => import("../views/DogRegistrationView.vue"),
      },
      {
        name: "dogHandlers",
        path: "handlers",
        component: () => import("../views/DogHandlerView.vue"),
      },
      {
        name: "dogClassList",
        path: "classlist/:trialType/:trialId",
        component: () => import("../components/dog/DogClassList.vue"),
        props: (route) => ({
          trialType: route.params.trialType,
          trialId: route.params.trialId,
        }),
      },
      {
        name: "dogClassResults",
        path: "trial/:trialId/classresults/:classId",
        component: () => import("../views/DogClassResultsView.vue"),
        props: (route) => ({
          dogId: route.params.dogId,
          classId: route.params.classId,
          trialId: route.params.trialId,
        }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(function (to, from, next) {
  if (to.meta.requireAuth && !store.getters["user/isAuthorized"]) {
    store.dispatch("user/clearUserProfile");
    next({ name: "authrequired" });
  } else if (to.meta.unAuthorized && store.getters["user/isAuthorized"]) {
    next({ name: "ownerdashboard" });
  } else {
    next();
  }
});

export default router;
