<template>
  <topNav v-if="isAuthCallback" />
  <router-view />
</template>

<script>
import TopNav from "./nav/TopNav.vue";
export default {
  components: { TopNav },
  computed: {
    isAuthCallback() {
      return this.$route.name !== "authcallback";
    },
  },
  async beforeCreate() {
    if (this.$store.getters["user/hasLocalTokens"]) {
      await this.$store.dispatch("user/getAuth0UserProfile");
    }
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  color: $textPrimary;
  font-family: "Roboto", sans-serif;
  background: $siteBackground;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
