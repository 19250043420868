import axios from "axios";
const envURL = process.env.VUE_APP_ENVURL;

// ****** POST routes ******
export const createTrial = async (token, userId, trial) => {
  const response = await axios.post(`${envURL}/api/trial/createtrial`, trial, {
    headers: { "x-auth-token": token, "x-auth-userId": userId },
  });
  return response;
};

export const createTrialClass = async (token, trialClass) => {
  const response = await axios.post(
    `${envURL}/api/trialclass/createtrialclass`,
    trialClass,
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

// ****** GET routes ******
export const upcomingOwnerTrials = async (token, userId) => {
  const response = await axios.get(`${envURL}/api/trial/ownertrials/upcoming`, {
    headers: { "x-auth-token": token, "x-auth-userId": userId },
  });
  return response;
};

export const pastOwnerTrials = async (token, userId) => {
  const response = await axios.get(`${envURL}/api/trial/ownertrials/past`, {
    headers: { "x-auth-token": token, "x-auth-userId": userId },
  });
  return response;
};

export const getSingleTrial = async (token, trialId) => {
  const response = await axios.get(`${envURL}/api/trial/${trialId}`, {
    headers: { "x-auth-token": token },
  });
  return response;
};

export const getTrialClasses = async (token, trialId) => {
  const response = await axios.get(`${envURL}/api/trial/${trialId}/classes`, {
    headers: { "x-auth-token": token },
  });

  return response;
};

export const getTrialLocations = async (token, userId) => {
  const response = await axios.get(`${envURL}/api/trial/locations/${userId}`, {
    headers: { "x-auth-token": token },
  });

  return response;
};

// ****** UPDATE routes ******
export const trialRegistration = async (token, trialId, dogId) => {
  const response = await axios.patch(
    `${envURL}/api/trial/register`,
    { trialId, dogId },
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

export const trialClassRegistration = async (
  token,
  trialId,
  classId,
  dogId,
  handler
) => {
  const response = await axios.patch(
    `${envURL}/api/trial/class/register`,
    { trialId, classId, dogId, handler },
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

export const updateTrial = async (token, trialId, update) => {
  const response = await axios.patch(
    `${envURL}/api/trial/${trialId}/update`,
    { ...update },
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

export const updateTrialClass = async (token, classId, update) => {
  const response = await axios.patch(
    `${envURL}/api/trial/class/${classId}/update`,
    { ...update },
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};

// ****** Delete routes ******

export const removeDogFromTrial = async (token, dogId, trialId) => {
  const response = await axios.patch(
    `${envURL}/api/dog/${dogId}/trial/${trialId}/remove`,
    {},
    {
      headers: { "x-auth-token": token },
    }
  );
  return response;
};

export const trialClassRemoval = async (token, trialId, classId, dogId) => {
  const response = await axios.patch(
    `${envURL}/api/trial/class/remove`,
    { trialId, classId, dogId },
    {
      headers: { "x-auth-token": token },
    }
  );

  return response;
};
